import { ReservationDetailsDTO } from "../../../../../store/endpoints/reservation-table.endpoints";
import * as React from "react";
import { FC, SyntheticEvent, useMemo } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import { useTranslationWrapper } from "../../../../../hooks/use-translation-wrapper";
import { ReservationTabs, TabsList } from "./reservation-details-constants";
import { ReservationDetailsOverview } from "../reservation-details-overview";
import ReservationDetailsComments from "../reservation-details-comments";
import { AllowedActionEnum } from "src/domain/reservation-table-dto";
import ReservationDetailsGuestInsight from "../reservation-details-guest-insight";
import { ReservationDetailsKeys } from "../reservation-details-keys";
import ReservationDetailsServices from "../reservation-details-services";
import { ReservationDetailsNotifications } from "./reservation-details-notifications";
import { grey } from "@mui/material/colors";
import { ChipStatus } from "../../../../../components/chip/ChipStatus";
import { Notification } from "../../../../../components/notification";
import { buttonVariantMap } from "../../../../../components/chip/ChipButton";
import {
  DoorProvider,
  useDoorProviderConfig,
  usePropertyConfig
} from "@likemagic-tech/sv-magic-library";
import { useProperty } from "../../../../../hooks/use-property";
import { Task } from "../../../../tasks/domain/task";
import { ReservationDetailsFolios } from "./reservation-details-folios/reservation-details-folios";
import { Params, useSelectId } from "../../../../../hooks/use-select-id";

interface ReservationDetailsTabsProps {
  reservation: ReservationDetailsDTO;
  tasks: Task[];
}

export const ReservationDetailsTabs: FC<ReservationDetailsTabsProps> = ({ reservation, tasks }) => {
  const { t } = useTranslationWrapper();
  const { selectedId: selectedTab, setSelectId: setSelectedTab } = useSelectId({
    fieldName: Params.SELECTED_TAB
  });
  const { selectedProperty } = useProperty();
  const { features } = usePropertyConfig({
    propertyId: selectedProperty?.propertyId
  });
  const doorConfig = useDoorProviderConfig(reservation.propertyId);

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const changeTabMoreInfo = (tab: string) => {
    setSelectedTab(tab as ReservationTabs);
  };

  const isChangeCommentAllowed = reservation?.allowedActions?.includes(
    AllowedActionEnum.CHANGE_RESERVATION_COMMENT
  );

  const tabsBasedOnFeatures = useMemo(
    () => TabsList.concat(features?.tfeFeatureEnabled ? [ReservationTabs.BILLING] : []),
    [features?.tfeFeatureEnabled]
  );

  return (
    <>
      {reservation?.reservationConditionReasons && (
        <Box>
          {Object.entries(reservation.reservationConditionReasons).map(
            ([reasonKey, value]) =>
              value === ChipStatus.CRITICAL && (
                <Box my={1} key={`key-${reasonKey}-${value}`}>
                  <Notification
                    type={buttonVariantMap[reservation.reservationConditionReasons[reasonKey]]}
                    title={t(`labels__reservation_condition_${reasonKey}`)}
                  />
                  {features?.imsFeatureEnabled && reservation.id === "JDCLCBUC-1" && (
                    <Notification
                      type={buttonVariantMap[reservation.reservationConditionReasons[reasonKey]]}
                      title="WiFi coverage unstable in room. Check with technician."
                    />
                  )}
                </Box>
              )
          )}
        </Box>
      )}
      <Box sx={{ width: "100%" }}>
        <TabContext value={selectedTab ?? ReservationTabs.OVERVIEW}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              position: "sticky",
              bgcolor: "background.default",
              top: 0
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label="Reservation Details tabs"
              variant="scrollable"
            >
              {tabsBasedOnFeatures.map((item) => (
                <Tab
                  label={t(`labels__tab_title_${item}`)}
                  value={item}
                  key={item}
                  sx={{
                    textTransform: "none",
                    color: grey[600],
                    fontWeight: "bold"
                  }}
                />
              ))}
            </TabList>
          </Box>
        </TabContext>
        {(selectedTab === ReservationTabs.OVERVIEW || !selectedTab) && (
          <ReservationDetailsOverview
            reservation={reservation}
            tasks={tasks}
            changeTabMoreInfo={changeTabMoreInfo}
          />
        )}
        {selectedTab === ReservationTabs.COMMENTS && (
          <ReservationDetailsComments
            reservation={reservation}
            isChangeCommentAllowed={isChangeCommentAllowed}
          />
        )}
        {selectedTab === ReservationTabs.INSIGHTS && (
          <ReservationDetailsGuestInsight reservation={reservation} />
        )}
        {selectedTab === ReservationTabs.KEYS &&
          doorConfig?.doorProvider !== DoorProvider.OFFLINE_KEY && (
            <ReservationDetailsKeys reservation={reservation} />
          )}
        {selectedTab === ReservationTabs.SERVICES && (
          <ReservationDetailsServices reservation={reservation} />
        )}
        {selectedTab === ReservationTabs.NOTIFICATIONS && (
          <ReservationDetailsNotifications reservation={reservation} />
        )}
        {features?.tfeFeatureEnabled && selectedTab === ReservationTabs.BILLING && (
          <ReservationDetailsFolios reservation={reservation} />
        )}
      </Box>
    </>
  );
};
